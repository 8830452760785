import DashboardLayout from "@/pages/layouts/DashboardLayout";

let mediaPartnerRoutes = {
  path: '/media-partners',
  component: DashboardLayout,
  redirect: '/media-partners/list',
  children: [
    {
      path: 'list',
      name: 'Media Partner List',
      component: () => import(/* webpackChunkName: "mediaPartners" */  '@/pages/mediaPartners/MediaPartnerList'),
      meta: {
        permissionsCodes: ['media-partners/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Media Partner',
      component: () => import(/* webpackChunkName: "mediaPartners" */  '@/pages/mediaPartners/MediaPartnerForm'),
      meta: {
        permissionsCodes: ['media-partners/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Media Partner',
      component: () => import(/* webpackChunkName: "mediaPartners" */  '@/pages/mediaPartners/MediaPartnerForm'),
      meta: {
        permissionsCodes: ['media-partners/update'],
      }
    }
  ]
}

export default mediaPartnerRoutes;
